// export const schoolTypes = {
//   ELEMENTARY_SCHOOL: 1,
//   // special as in: 'special-needs'
//   ELEMENTARY_SCHOOL_SPECIAL: 2,
//   HIGH_SCHOOL: 3,
//   // special as in: 'special-needs'
//   HIGH_SCHOOL_SPECIAL: 4
// }

export const schoolTypes = [
  {
    id: 1,
    name: 'Basisonderwijs'
  },
  {
    id: 2,
    name: 'Speciaal (basis)onderwijs'
  },
  {
    id: 3,
    name: 'Voortgezet onderwijs'
  },
  {
    id: 4,
    name: 'Speciaal voortgezet onderwijs'
  }
]
