<template>
  <!-- TODO: Maybe we can merge this with the global button component. -->
  <button :class="customClass" class="base-button button-action" :style="cssVars">
    <!-- <img :src="icon" class="action-icon" alt=""/> -->
    <inline-svg :src="icon"
                fill="var(--icon-color)"
                class="action-icon"
    />
    {{ text }}
  </button>
</template>

<script>
import { computed } from '@vue/runtime-core'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'ActionButton',
  components: {
    InlineSvg
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    color: {
      type: String,
      required: false,
      default: 'white'
    }
  },
  setup(props) {
    const cssVars = computed(() => {
      return {
        '--icon-color': props.color
      }
    })

    return {
      cssVars
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.button-action {
  align-items: center;
  border-radius: rem(8);
  box-shadow: rem(1) rem(1) 0 0 rgba(0,0,0,0.1);
  color: white;
  display: flex;
  font-size: rem(14);
  font-weight: 500;
  letter-spacing: 0;
  line-height: rem(17);
  padding: rem(8.5) rem(10);
  white-space: nowrap;

  .action-icon {
    margin-right: rem(7.5);
    object-fit: contain;
    :deep g {
      fill: var(--icon-color);
    }
  }
}

.button-action:hover {
  filter: brightness(0.9);
}

.button-action:active {
  filter: brightness(0.8);
}

.button-red {
  background-color: var(--red_main);
}

.button-green {
  background-color: var(--green_main);
}

.button-blue {
  background-color: var(--blue_dark);
}

.delete-button {
  background-color: var(--red_main);
  height: rem(40);
  font-size: rem(16);

  .action-icon {
    height: rem(24);
    width: rem(24);
  }
}
</style>
