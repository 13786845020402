<template>
  <div class="content-container">
    <PageTitle :title="$t('SCHOOLS')" class="page-title">
      <template #extra-content>
        <ActionButton :icon="require('../../../assets/icons/icn_delete.svg')" class="delete-button" :text="$t('SCHOOL_DETAILS.DELETE_SCHOOL')" @click="deleteSchoolWarningButtonPressed" />
      </template>
    </PageTitle>

    <h1 class="page-section-title section-title">{{ $t('SCHOOL_EDIT.EDIT') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="ROUTE_NAMES_HELPDESK.SCHOOL_DETAILS" :params="{ schoolId: schoolId }"/>

    <!-- Form -->
    <FormWrapper v-if="currentSchool" :send-form-data="validateData" class="page-form">
      <template #form-content>
        <!-- School Name -->
        <InputField :field-title="$t('SCHOOL_CREATE.SCHOOL_NAME')"
                    :field-name="'name'"
                    :rules="'required'"
                    :type="'text'"
                    :value="currentSchool.name"
                    :placeholder="$t('SCHOOL_CREATE.SCHOOL_NAME_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :api-error-text="''"
                    class="form-element"/>

        <div class="two-column-wrapper address-columns">
          <!-- Address -->
          <InputField :field-title="$t('SCHOOL_CREATE.STREET')"
                      :field-name="'streetName'"
                      :rules="'required'"
                      :type="'text'"
                      :value="currentSchool.streetName"
                      :placeholder="$t('SCHOOL_CREATE.STREET_PLACEHOLDER')"
                      :cy-selector="'name-input'"
                      :api-error-text="''"
                      class="form-element-column-wide"/>

          <!-- Address -->
          <InputField :field-title="$t('SCHOOL_CREATE.HOUSE_NUMBER')"
                      :field-name="'houseNumber'"
                      :rules="'required'"
                      :type="'text'"
                      :value="currentSchool.houseNumber"
                      :placeholder="1"
                      :cy-selector="'name-input'"
                      :api-error-text="''"
                      class="form-element-column"/>
        </div>

        <div class="two-column-wrapper address-columns">
          <!-- Postcode -->
          <InputField :field-title="$t('POSTCODE')"
                      :field-name="'postalCode'"
                      :rules="'required'"
                      :type="'text'"
                      :value="currentSchool.postalCode"
                      :placeholder="$t('POSTCODE_PLACEHOLDER')"
                      :cy-selector="'students-input'"
                      :api-error-text="''"
                      class="form-element-column"/>
          <!-- City -->
          <InputField :field-title="$t('CITY')"
                      :field-name="'city'"
                      :rules="'required'"
                      :type="'text'"
                      :value="currentSchool.city"
                      :placeholder="$t('CITY_PLACEHOLDER')"
                      :cy-selector="'city-input'"
                      :api-error-text="''"
                      class="form-element-column"/>
        </div>

        <!-- Province -->
        <InputField :field-title="$t('SCHOOL_EDIT.PROVINCE')"
                    :field-name="'province'"
                    :rules="'required'"
                    :type="'text'"
                    :value="currentSchool.province"
                    :placeholder="$t('PROVINCE_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :api-error-text="''"
                    class="form-element"/>

        <!-- Group -->
        <Dropdown id="schoolType"
                  :field-title="$t('SCHOOL_CREATE.SCHOOL_TYPE')"
                  :dropdown-options="schoolTypeOptions"
                  :placeholder="$t('SCHOOL_CREATE.SCHOOL_TYPE_PLACEHOLDER')"
                  :selected-option="schoolTypeOptions.find((option) => option.name === currentSchool.schoolType)"
                  :field-name="'schoolTypeName'"
                  :callback="() => {}"
                  :api-error-text="''"
                  class="form-element"/>
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t('SAVE_CHANGES') }}
        </button>
      </template>

    </FormWrapper>

    <!-- "Save changes?" Warning -->
    <DeleteSchoolWarning v-if="activeBasicModal === MODAL_NAMES.DELETE_SCHOOL" ref="deleteWarningModal"/>
    <BasicModal v-if="activeBasicModal === MODAL_NAMES.DELETE_SCHOOL_NOT_POSSIBLE" single-button="true" />
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import FormWrapper from '@/components/yo-form/FormWrapper'
import InputField from '@/components/yo-form/InputField'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { RESET_BASIC_MODAL_DATA, SET_BASIC_MODAL_DATA, SET_TOAST_DATA } from '@/store/mutations'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import { DELETE_SCHOOL } from '@/store/modules/helpdesk/actions'
import ApiHandler from '@/utils/handlers/ApiHandler'
import Dropdown from '@/components/yo-form/Dropdown'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed, ref } from '@vue/runtime-core'
import ActionButton from '@/components/table/ActionButton'
import DeleteSchoolWarning from '@/components/elements/helpdeskSchools/DeleteSchoolWarning'
import BasicModal from '@/components/elements/basicModal/BasicModal'
import { schoolTypes } from '@/utils/data/schoolTypes'

export default {
  name: 'SchoolEdit',
  components: {
    BackButton,
    FormWrapper,
    PageTitle,
    InputField,
    ActionButton,
    Dropdown,
    DeleteSchoolWarning,
    BasicModal
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const schoolId = route.params.schoolId
    const activeBasicModal = computed(() => store.getters.getActiveBasicModal)
    const deleteWarningModal = ref(null) // The ref of the Vue component so we can access it.

    const currentSchool = ref()
    const schoolTypeOptions = schoolTypes;


    // todo fix hardcoded data
    ApiHandler.get(`helpdesk/school/get-school-details/${schoolId}`)
      .then(response => {
        if (response) {
          currentSchool.value = response.data
          return true
        }
        return false
      })
      .catch(error => {
        console.log(error)
      })

    function reverseLookup(list, name) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].name === name) {
          return list[i].id
        }
      }
    }

    /** Form **/
    function validateData(data) {
      const formData = new FormData()
      formData.append('id', schoolId)
      formData.append('name', data.name)
      formData.append('streetName', data.streetName)
      formData.append('houseNumber', data.houseNumber)
      formData.append('postalCode', data.postalCode)
      formData.append('city', data.city)
      formData.append('schoolTypeId', reverseLookup(schoolTypeOptions, data.schoolTypeName))
      formData.append('province', data.province)
      ApiHandler.post('helpdesk/school/edit-school', formData).then((reponse) =>
      {
        router.push({ name: ROUTE_NAMES_HELPDESK.SCHOOL_DETAILS, params: { schoolId: schoolId } })
      }).catch(error => {
      })
    }

    function deleteSchoolWarningButtonPressed() {
      if (currentSchool.value.linkedTeachers.length === 0) {
        deleteSchoolWarning()
      } else {
        cantDeleteWarning()
      }
    }

    function cantDeleteWarning() {
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.DELETE_SCHOOL_NOT_POSSIBLE(),
          cancelCallback: null,
          executeCallback: () => store.commit(RESET_BASIC_MODAL_DATA),
        },
        activeModal: MODAL_NAMES.DELETE_SCHOOL_NOT_POSSIBLE
      }
      // After successful validation, the user should see a warning popup.
      store.commit(SET_BASIC_MODAL_DATA, payload)
    }

    function deleteSchoolWarning() {
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.DELETE_SCHOOL(currentSchool.value.name),
          cancelCallback: null,
          executeCallback: onSchoolDeletion
        },
        activeModal: MODAL_NAMES.DELETE_SCHOOL
      }
      // After successful validation, the user should see a warning popup.
      store.commit(SET_BASIC_MODAL_DATA, payload)
    }

    function onSchoolDeletion() {
      // Check if the checkboxes have been all clicked. If they are not, an error is automatically displayed.
      const areAllCheckboxesChecked = deleteWarningModal.value.validateCheckboxes()
      if (areAllCheckboxesChecked) {
        deleteSchool()
        store.commit(RESET_BASIC_MODAL_DATA)
      }
    }

    function deleteSchool() {
      store.dispatch(DELETE_SCHOOL, schoolId).then(() => {
        router.push({ name: ROUTE_NAMES_HELPDESK.SCHOOL_OVERVIEW })
        store.commit(SET_TOAST_DATA, { text: 'School succesvol verwijderd', src: require('@/assets/icons/icn_successful.svg') })
      })
    }


    return {
      currentSchool,
      ROUTE_NAMES_HELPDESK,
      schoolTypeOptions,
      deleteWarningModal,
      MODAL_NAMES,
      DELETE_SCHOOL,
      activeBasicModal,
      schoolId,

      /** Form **/
      deleteSchoolWarningButtonPressed,
      validateData,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.address-columns {
  margin-bottom: rem(28);
}

</style>
