<template>
  <div :class="customClass" class="check-wrapper">
    <label :for="id" class="check-label">
      <input :id="id"
             v-model="checkboxValue"
             :checked="isCheckboxChecked"
             type="checkbox"
             name="newsletter"/>
      <span v-if="labelText">{{ labelText }}</span>
      <span class="check-mark"></span>
    </label>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted, watch } from '@vue/runtime-core'

export default {
  name: 'BasicCheckbox',
  props: {
    // Required.
    id: {
      type: String,
      required: true
    },

    // Not required.
    labelText: {
      type: String,
      required: false,
      default: ''
    },
    callback: {
      type: Function,
      required: false,
      default: () => {}
    },
    isCheckboxChecked: {
      type: Boolean,
      default: false
    },
    checkboxData: {
      type: Object,
      required: false,
      default: () => { return {} }
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    resetCheckbox: {
      type: Number,
      required: false,
      default: 0
    }
  },
  setup(props) {
    const checkboxValue = ref(false)

    onMounted(() => {
      if (props.isCheckboxChecked) {
        checkboxValue.value = props.isCheckboxChecked
      }
    })

    watch(() => props.resetCheckbox, () => {
      checkboxValue.value = false
    })
    
    watch(() => checkboxValue.value, () => {
      updateValue()
    })

    function updateValue() {
      if (props.callback) {
        props.callback(props.id, props.checkboxData, checkboxValue.value)
      }
    }

    return {
      checkboxValue,
    }
  },
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.functions";
@import "~@/assets/css/base.mixins.scss";
@import "~@/assets/css/base.variables.scss";

.label-checkbox {
  margin-left: rem(15);
}

.check-label {
  margin: 0;
  padding: rem(5) 0 rem(5) rem(45);
  display: block;
  position: relative;
  cursor: pointer;
  text-align: left;
  @include user-select(none);

  &:checked {
    color: var(--pink_main);
  }

  input {
    display: none;
    cursor: pointer;

    &:checked ~ .check-mark {
      background-color: var(--pink_main);
      border: none;

      &:after {
        display: block;
      }
    }
  }

  .check-mark {
    @include border-radius(rem(8));
    height: rem(28);
    width: rem(28);
    background-color: white;

    &:after {
      @include position(absolute, $top: rem(3), $left: rem(10));
      @include rotate(45deg);
      content: "";
      display: none;
      border: solid white;
      border-width: 0 rem(2) rem(2) 0;
      height: rem(16);
      width: rem(9);
    }
  }

  .check-mark-error {
    @extend .check-mark;
  }
}

.check-label-round {
  @extend .check-label;

  .check-mark {
    @include border-radius(50%);
  }
}

.small-gray {
  .check-label {
    padding: rem(5) 0 rem(5) rem(35);

    &:checked {
      color: var(--blue_dark);
    }

    input {
      &:checked ~ .check-mark {
        background-color: var(--blue_dark);
      }
    }

    .check-mark {
      @include position(absolute, $top: rem(5), $left: 0);
      background-color: white;
      border: rem(2) solid #9195AD;
      border-radius: rem(4);
      height: rem(19);
      width: rem(19);

      &:after {
        @include position(absolute, $top: rem(2), $left: rem(7));
        height: rem(12);
        width: rem(6);
      }
    }
  }
}
</style>
